.team .box {

  border-radius: 6px;
  border: 1px solid rgb(62 28 131 / 10%);
  padding: 2rem;
  transition: 0.5s;
}

.team .box:hover {
  box-shadow: 0 0 20px 0 rgb(112 121 138 / 18%);
  cursor: pointer;
}

.team .details {
  text-align: center;
  margin-top: 30px;
}

.team .img {
  width: 90px;
  height: 90px;
  margin: auto;
  position: relative;
}

.team img {
  width: 90px;
  height: 90px;
  border-radius: 50%;
}

.team .img i {
  color: #108be7;
  font-size: 17px;
  position: absolute;
  top: 35px;
  right: -10px;
}

.team .img::after {
  content: "";
  position: absolute;
  top: -11px;
  left: -11px;
  width: 110px;
  height: 110px;
  border: 5px solid #edf0f5;
  border-radius: 50%;
}

.team label {
  display: inline-block;
  margin-top: 20px;
}

.team i {

  margin-right: 5px;
}

.team ul {
  display: inline-block;
  margin-top: 10px;
}

.team ul li {
  display: inline-block;
  width: 40px;
  height: 40px;
  line-height: 40px;
  align-items: center;
  background-color: #f4f5f7;
  border-radius: 50%;
  margin: 5px;
}

.team .button {
  margin-top: 20px;
}

.team .button i {
  color: rgb(238, 15, 15);
  font-size: 20px;
}

.row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  flex-direction: column;
}

.col {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  flex-basis: 100%;
}

.col i {

  font-size: 2.5rem;
  margin-right: 1rem;
}

.col span {
  font-size: 1.2rem;
  color: #555;
  word-break: break-word;
  font-weight: bolder;
}

.title-col {
  text-align: center;
  margin-top: 20px;
  font-size: 25px;
}

@media screen and (min-width: 768px) {
  .col {
    flex-basis: calc(33.33% - 1rem);
  }
}
.fa-map-marker-alt{
  color: #6698ff;
}
.fa-building{
  color: #6698ff;
}
.fa-id-card{
  color: #6698ff;
}
.fa-envelope{
  color:  #6698ff;
}
@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap");


* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  font-family: "Roboto", sans-serif;
  background-color: #fff;
}

.btn-watch {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: inherit;
  border: 2px solid white;
  height: 60px;
  width: 30px;
}

.btn-watch:hover {
  background-color: white;
  color: gray;
  font-weight: bold;
}

.btn-watch i {
  margin-right: -3px;
  font-size: 24px;
}

.btn-nobg {
  background-color: inherit;
  border: 2.5px solid white;
  cursor: pointer;

}

.btn-nobg:hover {
  background-color: white;
  color: gray;

}

.containers {
  max-width: 80%;
  margin: auto;
}

.containers-full {
  max-width: 70%;
  margin: auto;
}

.flexs {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

a {
  text-decoration: none;
  color: #000;
  text-transform: capitalize;
}

ul {
  list-style-type: none;
}

button {
  border-radius: 2px;
  padding: 13px 30px;
  /* background: #6698FF; */
  border: none;
  color: #fff;
  cursor: pointer;
  font-weight: 600;
}

button i {
  margin-right: 5px;
}

.heading {
  text-align: center;
  width: 60%;
  margin: auto;
}

.heading h1 {
  color: #2d3954;
  font-weight: 600;
  text-transform: capitalize;
  font-size: 35px;
}

.heading p {
  color: #72809d;
}

.headingAbout {
  text-align: center;
  width: 100%;
  margin: auto;
}

.headingAbout h1 {
  font-weight: bolder;
  font-family: sans-serif;
  font-size: 38px;
}

textarea,
input {
  border: none;
  outline: none;
  background: none;
}

.background {
  padding: 50px 0;
  position: relative;
  background: #f7f9fc;
}

.backgrounds {
  padding: 50px 0;
  position: initial;
  background: #f7f9fc;
}

.grid5 {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 30px;
}

label {
  color: #2d3954;
  font-size: 14px;
}

.mtop {
  margin-top: 50px;
}

h4 {
  font-weight: 500;
  margin-top: 10px;
}

.shadow {
  box-shadow: 0 0 20px 0 rgb(112 121 138 / 18%);
}

.padding {
  padding: 80px 0;
}

.grid3 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 30px;
}

.grid2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 30px;
}

img {
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.btn2 {
  border-radius: 50px;
  font-size: 20px;
}

.grid4 {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 30px;
}

.btn3 {
  background: #ff6922;
  border-radius: 50px;
}

.btn4 {
  background-color: #ffffff;
  color: #c60b0b;
}

.btn5 {
  border-radius: 50px;
  padding: 20px 40px;
  color: #007bff;
  font-size: 20px;
  font-weight: 400;
  border: 5px solid #2156e91f;
  background: #fff;
}

.back {
  height: 40vh;
  position: relative;
}

.back img {
  height: 40vh;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  object-fit: cover;
}

.back::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 40vh;
  background: rgba(17, 40, 72, 0.629);
  z-index: -1;
}

.back .container {
  color: #fff;
  padding: 120px 0;
}

.back h1 {
  font-size: 40px;
  font-weight: 500;
}

.mb {
  margin-bottom: 80px;
}

@media screen and (max-width: 800px) {

  .grid4,
  .grid3,
  .grid5 {
    grid-template-columns: repeat(1, 1fr);
  }

  .heading {
    width: 100%;
  }

  .container {
    max-width: 90%;
  }

  .back::after,
  .back img,
  .back {
    height: 30vh;
  }
}

.btn_gmail {
  border-radius: 5px;
  padding: 17px 30px;
  background: #ffffff;
  border: none;
  color: #fff;
  cursor: pointer;
  font-weight: bold;
  background-color: #ffffff;
}

.slick-slide>div {
    margin: 0 10px;
}

.slider {
    width: 100%;
    margin: 0 auto;
    margin-top: 50px;
    position: relative;
    z-index: 1;
}

.slider .next {
    content: " ";
    position: absolute;
    left: -15px;
    z-index: 99999999;
    top: 43%;
    width: 40px;
    height: 40px;
    background-image: url("https://mytindigital.com/images/prev.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    /* backgroundObjectFit: "cover"; */
    background-size: cover;
    object-fit: cover;
}

.slider .prew {
    content: " ";
    position: absolute;
    right: -15px;
    z-index: 99999999;
    top: 43%;
    width: 40px;
    height: 40px;
    background-image: url("https://mytindigital.com/images/next.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    /* backgroundObjectFit: "cover"; */
    background-size: cover;
    object-fit: cover;
}

.slick-list {
    margin: 0 -10px;
}

.card {
    /* border: 1px solid #6698ff; */
    border: 1px solid #6698ff;
    /* background: black; */
    border-radius: 8px;
    overflow: hidden;
    height: 400px;
    /* color: #fff; */
    color: black;
    cursor: pointer;
    z-index: 1;
}

.slider::before {
    /* content: " "; */
    /* position: absolute; */
    /* left: -40px; */
    /* top: 43%; */
    /* width: 40px; */
    /* height: 40px;
    background-image: url("https://mytindigital.com/images/prev.png"); */
    /* background-size: cover; */
    /* background-position: center; */
    /* background-repeat: no-repeat; */
    /* backgroundObjectFit: "cover"; */
    /* background-size: cover; */
    /* object-fit: cover; */
}

.card-top h1 {
    font-size: 0.9rem;
    margin: 20px;

}

.card-top>img {
    width: 100%;
    height: 100% !important;
    object-fit: cover;
    background: black;
}

.card-top img {
    width: 100% !important;
    /* max-height: 850px !important; */
}

.card-bottom {
    margin: 10px 20px;
}

.category {
    position: relative;
}

.category::before {
    content: '';
    background: #6698ff;
    position: absolute;
    top: calc(100% + 10px);
    left: 0;
    right: 0;
    height: 2px;
}
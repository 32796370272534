main .containers {
  display: flex;
  justify-content: space-between;
}
.sideContenthide
{
  width: 100%;
 display: none;
}
.mainContent {
  width: 75%;
  padding-bottom: 50px;
}

.sideContent {
  width: 23%;
}

.sideContent .heading h6 {
  background-color: #000;
}

@media screen and (max-width: 768px) {
  main .containers {
    flex-direction: column;
  }

  .mainContent {
    width: 100%;
  }

  .sideContent {
    width: 100%;
    display: none;
  }

  .sideContenthide {
    width: 100%;
    display: contents;
    
  }
}
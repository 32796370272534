.hero {
  
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 80vh;
  width: 100%;
  filter: brightness(70%);
}

.hero h1 {
  color: #fff;
}

.hero p {
  color: #fff;
  opacity: 0.8;
}

form {
  background-color: #fff;
  border-radius: 5px;
}

form input {
  width: 100%;
  border: 1px solid rgba(128, 128, 128, 0.2);
  margin-top: 5px;
  border-radius: 5px;
}

form span {
  font-size: 14px;
  color: grey;
}

input::placeholder {
  font-size: 17px;
  color: black;
}

form .box {
  padding: 15px;
  border-left: 1px solid rgba(128, 128, 128, 0.2);
}

form .box:nth-child(1) {
  border-left: none;
}

form h4 {
  font-weight: 500;
}

@media screen and (max-width: 800px) {
  .hero .container {
    padding-top: 30%;
  }

  form {
    display: flex;
    flex-wrap: wrap;
  }

  form .box {
    border-left: none;
  }

  form input {
    width: 100%;
  }
}
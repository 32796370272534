
.chat-buttons {
    position: fixed;
    bottom: 150px;
    right: 25px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    z-index: 9999;
}
.chat-buttons ul li img {
    margin-bottom: 10px;
}
 .iconZalo{
    width: 55px;    
 }
 .iconPhone{
    width: 50px;
 }

 @keyframes zoomInOut {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.2);
    }
    100% {
      transform: scale(1);
    }
  }
  .iconPhone {
    animation-name: zoomInOut;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
  }
  .iconZalo{
    animation-name: zoomInOut;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
  }
  /* .scroll-btn {
    display: block;
    position: fixed;
    bottom: 30px;
    right: 35px;
    z-index: 99999;
    width: 45px;
    height: 55px;
    border: 3px solid black;
    color: #205aa7;
    font-size: 30px;
    line-height: 0.2;
    text-align: center;
    border-radius: 50%;
    cursor: pointer;
    transition: opacity 0.2s;
    background: white;
  }
  .scroll-btn i {
    display: inline-block;
    vertical-align: middle;
    margin-left: -11px;
    margin-bottom: 6px;
  }
  @media (max-width: 768px) {
    .scroll-btn {
      width: 30px;
      height: 40px;
      font-size: 18px;
    }
  
    .scroll-btn i {
      font-size: 18px;
    }
  } */
  .scrollButton {
    position: fixed;
    bottom: 100px;
    right: 30px;
    z-index: 1;
    z-index: 9999;
  }
  
  .scrollButton button {
    background-color: #ffffff;
    color: #6698FF;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 10px;
    border-radius: 4px;
    font-size: 18px;
    width: 40px;
    border-radius: 20px;
    border: 3px solid black;
    width: 40px;
    height: 47px;
  }
  @media (max-width: 768px) {
    .scrollButton{
      right: 7px;
    }
    .chat-buttons{
      right: 7px;
    }
    .iconZalo{
      width: 40px;    
   }
   .iconPhone{
    width: 40px;
 }
  }
.tpost .box {
    margin-bottom: 20px;
  }
  .tpost .img {
    width: 100px;
    height: 80px;
  }
  .tpost img {
    width: 100px;
    height: 80px;
    object-fit: cover;
    /* text-align: center;
    justify-content: center; */
  }
  .tpost .text {
    padding: 0 0px;
  }
  .tpost h1 {
    font-size: 15px;
  }
  .tpost span {
    font-size: 12px;
    color: grey;
  }
  @media screen and (max-width: 768px) {
    .tpost .img,
    .tpost img {
      width: 200px;
      height: 150px;
      display: none;
    }
    .tpost .text {
      width: 100%;
      display: none;
    }
  }
  
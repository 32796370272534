.card-container {
	background-color: white;
	min-width: 100%;
	max-width: 100%;
	height: auto;
	border-radius: 14px;
	box-shadow: 0px 0px 15px rgba(134, 131, 131, 0.7);
}

.headerUser {
    padding-top: 10px;
    padding-bottom: 10px;
	background-image: linear-gradient(to right, rgb(40, 231, 40) , rgb(14, 227, 231));
	/* background-image: url("../images/background.png"); */
	background-position: 0px 0px;
	background-repeat: no-repeat;
	background-size: cover;
	text-align: center;
	border-top-left-radius: 14px;
	border-top-right-radius: 14px;
}

.imgavt {
	margin: auto;
	width: 100px;
	border: solid white 4px;
	border-radius: 50%;
	margin-top: 75px;
} 

.bold-text {
	font-weight: bold;
	font-size: 1.1rem;
	text-align: center;
	padding: 10px 20px 0px 20px;
}

.normal-text {
	font-weight: normal;
	font-size: 0.95rem;
	color: hsl(0, 0%, 50%);
	text-align: center;
	padding-bottom: 10px;
}


.smaller-text {
	font-weight: normal;
	font-size: 0.7rem;
	color: hsl(0, 0%, 50%);
	text-align: center;
	letter-spacing: 1px;
	padding-bottom: 20px;
	line-height: 5px;
}

.social-container {
	display: flex;
	border-top: solid rgb(206, 206, 206) 1px;
	text-align: center;
}

.followers {
    padding-top: 20px;
    font-size: 18px;
	flex: 1;
}

.likes {
    padding-top: 20px;
    font-size: 18px;
	flex: 1;
}

.photos {
    padding-top: 20px;
    font-size: 18px;
	flex: 1;
}